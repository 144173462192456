import axios from 'axios';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';
import { searchTickers } from '../../api/searchTickers';
import { isDomestic, COUNTRY } from '../../lib/utils';
import { TICKCOLOR_UP, TICKCOLOR_DOWN, TICKCOLOR_NEUTRAL } from '../../lib/constants';
import { Autocomplete, Box, Chip, Container, Link, Stack, TextField, Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddFund from './AddFund';
import { LineHighlightPlot, LinePlot } from '@mui/x-charts/LineChart';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis';
import { ChartsTooltip } from '@mui/x-charts/ChartsTooltip';
import { ChartsAxisHighlight } from '@mui/x-charts/ChartsAxisHighlight';
import { axisClasses } from '@mui/x-charts/ChartsAxis';

const SearchAsset = () => {
  const navigate = useNavigate();

  const { token, preferences } = useSelector(state => state.auth);
  const tradingMode = preferences.tradingMode;

  const [tickers, setTickers] = useState([]);
  const [assetDetails, setAssetDetails] = useState(null);
  const [country, setCountry] = useState(COUNTRY.KOREA);
  const [plotData, setPlotData] = useState([]);

  const tableStyle = {
    fontSize: '14px',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    lineHeight: '52px',
    paddingLeft: '1rem'
  };

  const series = [
    {
      type: 'bar',
      yAxisId: 'open',
      label: 'Open',
      // color: 'yellow',
      data: plotData.map(day => day.open)
    },
    {
      type: 'line',
      yAxisId: 'price',
      label: 'Close',
      color: '#00AEEF',
      data: plotData.map(day => day.close)
      // highlightScope: { highlight: 'item' }
    },
    {
      type: 'bar',
      yAxisId: 'signal',
      label: 'Signal',
      // color: 'yellow',
      data: plotData.map(day => (day.bsignal === 0 ? 'Sell' : day.bsignal === 1 ? 'Buy' : 'Hold'))
    },
    {
      type: 'bar',
      yAxisId: 'avg5',
      label: '5-day Average',
      // color: 'green',
      data: plotData.map(day => day.avg5)
    },
    {
      type: 'bar',
      yAxisId: 'avg20',
      label: '20-day Average',
      // color: 'blue',
      data: plotData.map(day => day.avg20)
    },
    {
      type: 'bar',
      yAxisId: 'foreignRatio',
      // color: 'red',
      label: 'Foreign Ratio',
      data: plotData.map(day => day.foreignRatio),
      valueFormatter: (v, { dataIndex }) => {
        if (!v) return;

        const { foreignRatio } = plotData[dataIndex];
        return foreignRatio.toFixed(2) + '%';
      }
    }
  ];

  const handleChange = async e => {
    if (typeof e.target.value === 'string') {
      if (e.target.value.length === 0) setTickers([]);
      else {
        const tickers = await searchTickers(token, tradingMode, e.target.value);

        setTickers(tickers);
      }
    }
  };

  const getAssetHistory = async (ticker, country) => {
    const API_URL = `/api/${tradingMode.toLowerCase()}/assets/history/`;

    try {
      const config = {
        headers: {
          authorization: `Bearer ${token.token}`,
          'cache-control': 'no-store',
          pragma: 'no-store',
          expires: '0'
        },
        params: {
          ticker,
          country
        }
      };

      const response = await axios.get(API_URL + ticker, config);

      return response.data;
    } catch (error) {
      console.log(error.response.data);
      toast.error('Error: Invalid KIS credentials. Please update your credentials and try again later.');
      return 0;
    }
  };

  const displayDetails = async ticker => {
    const country = ticker.country;
    const marketCode = ticker.marketCode || null;

    setCountry(country);

    try {
      const { props, priceHistory } = await getAssetHistory(ticker.ticker, country);
      const change = props.close - props.yclose;
      const changeRatio = ((props.close - props.yclose) / props.yclose) * 100;
      const tick = change > 0 ? 'UP' : change < 0 ? 'DOWN' : 'HOLD';
      const tickColor = change > 0 ? TICKCOLOR_UP : change < 0 ? TICKCOLOR_DOWN : TICKCOLOR_NEUTRAL;

      setAssetDetails({
        name: ticker.name,
        ticker: ticker.ticker,
        close: isDomestic(country)
          ? props.close.toLocaleString('en-us')
          : `$${props.close.toFixed(2).toLocaleString('en-us')}`,
        yclose: isDomestic(country)
          ? props.yclose.toLocaleString('en-us')
          : `$${props.yclose.toFixed(2).toLocaleString('en-us')}`,
        change: isDomestic(country)
          ? Math.abs(change).toLocaleString('en-us')
          : `$${Math.abs(change).toFixed(2).toLocaleString('en-us')}`,
        changeRatio: isDomestic(country)
          ? `${Math.abs(changeRatio).toFixed(2).toLocaleString('en-us')}%`
          : `${Math.abs(changeRatio).toFixed(2).toLocaleString('en-us')}%`,
        foreignRatio: props.foreignRatio || 'N/A',
        avg5: isDomestic(country)
          ? props.avg5?.toLocaleString('en-us')
          : `$${props.avg5?.toFixed(2).toLocaleString('en-us')}`,
        avg20: isDomestic(country)
          ? props.avg20?.toLocaleString('en-us')
          : `$${props.avg20?.toFixed(2).toLocaleString('en-us')}`,
        high52w: isDomestic(country)
          ? props.high52w?.toLocaleString('en-us')
          : props.high52w
          ? `$${props.high52w?.toFixed(2).toLocaleString('en-us')}`
          : 'N/A',
        low52w: isDomestic(country)
          ? props.low52w?.toLocaleString('en-us')
          : props.low52w
          ? `$${props.low52w?.toFixed(2).toLocaleString('en-us')}`
          : 'N/A',
        volume: props.volume.toLocaleString('en-us'),
        marketCap: isDomestic(country)
          ? `${Math.ceil(props.marketcap / 10).toLocaleString('en-us')}B`
          : `$${Math.ceil(props.marketcap).toLocaleString('en-us')}B`,
        signal: props.bsignal,
        marketCode,
        country,
        tick,
        tickColor
      });

      priceHistory.length > 0 && setPlotData(priceHistory);
    } catch (error) {
      toast.error('Error: Invalid KIS credentials. Please update your credentials and try again later.');
    }
  };

  useEffect(() => {
    if (!token) {
      return navigate('/login');
    }
  }, [token, navigate]);

  return (
    <Container maxWidth='lg' style={{ marginBottom: '2rem' }}>
      <Box
        maxWidth={{ xs: '360px', sm: '600px', md: '720px', lg: '1000px' }}
        sx={{ width: '100%', margin: 'auto', mt: 8, typography: 'body1' }}
      >
        <Stack spacing={2} sx={{ width: '100%', margin: 'auto' }}>
          <Autocomplete
            id='stock_search_autocomplete'
            freesolo='true'
            clearOnEscape
            clearOnBlur
            filterOptions={x => x}
            getOptionLabel={option => option.name}
            options={tickers}
            isOptionEqualToValue={(option, value) => option.ticker === value.ticker}
            noOptionsText={'No matching asset found'}
            renderOption={(props, option) => {
              return (
                <Box component={'li'} {...props} key={option.ticker}>
                  {option.name} ({option.ticker})
                </Box>
              );
            }}
            renderInput={params => <TextField {...params} label='Search an asset...' />}
            onChange={(e, newValue) => {
              if (newValue !== null) {
                displayDetails(newValue);
              }
            }}
            onInputChange={debounce(handleChange, 300)}
          />
        </Stack>

        {assetDetails && (
          <Stack spacing={4} sx={{ width: '100%', margin: { xs: '2rem auto', md: '4rem auto' } }}>
            <Box display={'flex'} gap={2} flexDirection={{ xs: 'column', md: 'row' }}>
              <Box display={'flex'} alignItems={'center'} gap={1} width={'100%'}>
                <Link
                  color='primary.light'
                  underline='none'
                  href={
                    assetDetails.country === 'KOREA'
                      ? `https://finance.naver.com/item/main.naver?code=${assetDetails.ticker}`
                      : `https://finance.yahoo.com/quote/${assetDetails.ticker}?p=${assetDetails.ticker}`
                  }
                  target='_blank'
                  rel='noreferrer'
                >
                  <Typography color={'#90caf9'}>{assetDetails.name}</Typography>
                </Link>
                <Typography>({assetDetails.ticker})</Typography>
              </Box>
              <Box display={'flex'} alignItems={'center'} gap={2} justifyContent={'space-between'} width={'100%'}>
                <Box display={'flex'} alignItems={'center'} gap={1}>
                  <Typography color={assetDetails.tickColor}>{assetDetails.close}</Typography>
                  <Typography color={assetDetails.tickColor} display={'flex'} alignItems={'center'}>
                    {assetDetails.tick === 'UP' ? (
                      <ArrowDropUpIcon />
                    ) : assetDetails.tick === 'DOWN' ? (
                      <ArrowDropDownIcon />
                    ) : (
                      ''
                    )}
                    {assetDetails.change.toLocaleString('en-us')} ({assetDetails.changeRatio})
                  </Typography>
                </Box>
                <Box display={'flex'} alignItems={'center'} gap={4}>
                  {assetDetails.signal === 0 ? (
                    <Chip label='SELL' color='success' sx={{ width: '62px' }} />
                  ) : assetDetails.signal === 1 ? (
                    <Chip label='BUY' color='error' sx={{ width: '62px' }} />
                  ) : assetDetails.signal === 2 ? (
                    <Chip label='HOLD' color='secondary' sx={{ width: '62px' }} />
                  ) : assetDetails.signal === 99 ? (
                    <Chip label='DELISTED' />
                  ) : (
                    ''
                  )}
                  {assetDetails.signal !== 99 && <AddFund asset={assetDetails} />}
                </Box>
              </Box>
            </Box>

            <Box
              display={'grid'}
              gridTemplateColumns={{ xs: 'repeat(2, 1fr)', sm: 'repeat(4, 1fr)' }}
              border={'1px solid rgba(255, 255, 255, 0.2)'}
              borderRadius={'6px'}
            >
              <Typography style={tableStyle} backgroundColor='#001021'>
                Prev Close
              </Typography>
              <Typography style={tableStyle}>{assetDetails.close}</Typography>
              <Typography style={tableStyle} backgroundColor='#001021'>
                Foreign Ratio
              </Typography>
              <Typography style={tableStyle} color={assetDetails.openColor}>
                {assetDetails.foreignRatio}
              </Typography>
              <Typography style={tableStyle} backgroundColor='#001021'>
                5-day Avg
              </Typography>
              <Typography style={tableStyle} color={assetDetails.highColor}>
                {assetDetails.avg5}
              </Typography>
              <Typography style={tableStyle} backgroundColor='#001021'>
                20-day Avg
              </Typography>
              <Typography style={tableStyle} color={assetDetails.lowColor}>
                {assetDetails.avg20}
              </Typography>
              <Typography style={tableStyle} backgroundColor='#001021'>
                52W High
              </Typography>
              <Typography style={tableStyle}>{assetDetails.high52w}</Typography>
              <Typography style={tableStyle} backgroundColor='#001021'>
                52W Low
              </Typography>
              <Typography style={tableStyle}>{assetDetails.low52w}</Typography>
              <Typography style={tableStyle} backgroundColor='#001021'>
                Volume
              </Typography>
              <Typography style={tableStyle}>{assetDetails.volume}</Typography>
              <Typography style={tableStyle} backgroundColor='#001021'>
                Market Cap
              </Typography>
              <Typography style={tableStyle}>{assetDetails.marketCap}</Typography>
            </Box>
          </Stack>
        )}

        {/* chart */}
        {plotData.length > 0 && (
          <Box display={'flex'} gap={2} py={4} flexDirection={'column'}>
            <ResponsiveChartContainer
              series={series}
              height={450}
              margin={{ top: 16, left: 60, right: 60 }}
              xAxis={[
                {
                  id: 'date',
                  data: plotData.map(day => new Date(day.date)),
                  scaleType: 'band',
                  valueFormatter: value => value.toLocaleDateString()
                }
              ]}
              yAxis={[
                {
                  id: 'price',
                  scaleType: 'linear',
                  valueFormatter: value => {
                    if (isDomestic(country)) {
                      return value;
                    } else {
                      return '$' + value;
                    }
                  }
                },
                {
                  id: 'foreignRatio',
                  scaleType: 'linear',
                  valueFormatter: value => value.toFixed(2) + '%'
                }
                // {
                //   id: 'signal',
                //   scaleType: 'linear',
                //   valueFormatter: value => (value === 0 ? 'SELL' : value === 1 ? 'BUY' : 'HOLD')
                // }
              ]}
            >
              <ChartsAxisHighlight x='line' />
              <LinePlot />
              <LineHighlightPlot />
              <ChartsXAxis
                label='Date'
                position='bottom'
                axisId='date'
                tickInterval={(value, index) => {
                  return index % 3 === 0;
                }}
                tickLabelStyle={{ fontSize: 12 }}
              />
              <ChartsYAxis
                label={isDomestic(country) ? 'Price (KRW)' : 'Price (USD)'}
                position='left'
                axisId='price'
                tickLabelStyle={{ fontSize: 12 }}
                sx={{
                  [`& .${axisClasses.label}`]: {
                    transform: 'translateX(-20px)'
                  }
                }}
              />
              <ChartsYAxis
                label='Foreign Ratio'
                position='right'
                axisId='foreignRatio'
                tickLabelStyle={{ fontSize: 12 }}
                sx={{
                  [`& .${axisClasses.label}`]: {
                    transform: 'translateX(20px)'
                  }
                }}
              />
              <ChartsTooltip
                slotProps={{
                  popper: {
                    sx: {
                      '& .MuiChartsTooltip-paper': {
                        backgroundColor: 'black'
                      }
                    }
                  }
                }}
              />
            </ResponsiveChartContainer>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default SearchAsset;
